<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_10.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Last step. Great Job!</span>
    </div>

    <div class="flex h-screen text-center" style="height: 80vh;">

      <div class="m-auto pb-10 w-2/3" v-if="!editable">

        <div class="m-auto" style="max-width: 800px;">
          <h2 class="mb-9">Don’t worry, we’ll save your spot!</h2>

          <img
            class="m-auto mb-5"
            src="@/assets/images/undraw/finish_later.svg"
          >

          <p class="mb-6 text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Adipiscing enim eu turpis egestas pretium aenean pharetra magna ac. Vel pharetra vel turpis nunc eget. Felis eget nunc lobortis mattis aliquam faucibus purus in. Malesuada fames ac turpis egestas sed tempus. Purus gravida quis blandit turpis cursus in hac. </p>
        </div>

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'v2.profile.cares'}"
            class="text-primary float-left"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left_blue.svg"
              class="inline"
            >
            Back
          </router-link>

          <router-link
            :to="{name: 'v2.profile.finish_later'}"
            class="btn btn-primary-outline btn-large align-self-center me-auto mr-10"
          >
            Finish Later
          </router-link>

          <a
            href="#"
            class="btn btn-primary btn-large align-self-center me-auto"
            @click="changeMode()"
          >
            Onwards
          </a>

          <router-link
            :to="{name: 'v2.profile.finish_later'}"
            class="text-primary float-right"
          >
            Skip

            <img
              src="@/assets/images/icons/ic_chevron_right_blue.svg"
              class="inline"
            >
          </router-link>
        </div>
      </div>

      <div class="m-auto pb-10 w-2/3" v-if="editable">

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FinishLater',
  data() {
    return {
      firstName: '',
      editable: false
    };
  },
  methods: {
    changeMode: function() {
      // this.editable = !this.editable;
    }
  },
  mounted() {
    let firstName = localStorage.getItem('preferredname');

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
</style>
